.calendar-main-frame{
    display: flex;
    overflow: hidden;
    flex-direction: column;
    height: 100%;
    gap:1rem
}

.calendar-wrapper{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 1px solid #d5d5d5;
    border-radius: 0.25rem;
}


.month-list-wrapper{
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
    height:100%;
    outline: 1px solid #d1d1d1;
}

.month-cell {
    height: 10rem;
    background: white;
    padding: .5rem;
    outline: 1px solid #d1d1d1;
    overflow:auto
}

.month-list{
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    outline: 1px solid #d1d1d1;
}

.month-header{
    background: white;
    display: flex;
    outline: 1px solid #d1d1d1;
    justify-content: center;
    font-weight: bold;
    flex: 1;
}

.month-list-header{
    display: flex;
}

.month-list::-webkit-scrollbar{
    width:0
}

.month-cell.otherday {
    color: gray;
}

.month-cell.today {
    background: rgb(255, 241, 194);
}

.select-month-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
}

.select-month-row > div{
    font-size: 1.5rem
}

.month-items{
}

.calendar-inlineflex{
    display:flex;
    gap:1rem
}

.calendar-inlineflex .inlineicon{
    width:1rem;
    height:1rem;
    border-radius:50%
}

.calendar-item-text{
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
    justify-content: flex-end
}

.calendar-item-text > span{
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis
}
