.cockpitbutton {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}

.cockpitbutton > button {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cockpitbuttonMobile {
    display: flex;
    gap: 0.7rem;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
}

.cockpitbuttonMobile > button {
  flex: 1;
  padding:8px ;
  font-size: .8rem; 
}

.cockpitmenu {
  display: flex;
  gap: 1rem;
  height: 100%;
  flex-direction: column;
  overflow: auto;
}

.action-left-options {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: flex-start;
}

.action-right-options {
  display: flex;
  gap: 1rem;
}

.product-service-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.product-service-list li {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 0;
}

.simple-label,
.simple-flex {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 0;
  flex: 0.85;
  align-items: center;
}

.single-line {
  border: 1px solid black;
  width: 100%;
  flex: 1;
}

.product-service-list .simple-text {
  flex: 0.15;
}

.product-service-list .simple-value {
  flex: 0.3;
}

.observation-service-list {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.observation-service-list li {
  padding: 0;
}

.observation-service-list li > div {
  width: 100%;
}

.proposal-resume-list .nopadding {
  padding: 0;
}

.proposal-resume-list div {
  padding: 0;
}

.proposal-resume-list .MuiListItemButton-gutters {
  padding: 0rem 1rem;
  flex: 1;
}

.proposal-resume-list .MuiListItemButton-gutters.flexunset {
  flex: unset;
}

.proposal-resume-list p {
  white-space: normal;
  overflow-wrap: break-word;
}

.product-service-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 0.25rem;
}

.dddrow--OPEN {
  color: black;
}

.dddrow--SCHEDULED {
  color: #0d6efd;
}

.dddrow--DONE {
  color: #24a024;
}

.dddrow--CANCELLED {
  color: #dc3545;
}


.multi-button-selector{
  width: 100%;
    display: flex;
    border: 1px solid #e2e2e2;
    border-radius: .25rem;
}

